.mymodal-content {
  width: 650px;
  height: 100%;
}

.hJJzxj:hover:enabled,
.hJJzxj:focus:enabled {
  background-color: #66fcf1;
}
.jYxeHE {
  background-color: #66fcf1;
  box-shadow: #66fcf1;
}
.mytitle {
  font-size: 100px;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
  background-color: #66fcf1;
  border-radius: 40px;
  /* margin: 30px 20%; */
}

.portfolio-filter li button.current {
  color: #66fcf1 !important;
}

.portfolio-filter li button {
  color: #fff !important;
}

/*=================================================================*/
/*                     BOOTSTRAP ELEMENTS
/*=================================================================*/
.breadcrumb {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb > .active {
  color: #9c9c9c;
}

/* === Progress Bar === */
.progress {
  height: 7px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #f1f1f1;
  border-radius: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  border-radius: 15px;
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 7px;
  color: #fff;
  text-align: center;
  background-color: $colordark;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

/* === Form Control === */
.form-control {
  display: block;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: $colordefault;
  background-color: #fff;
  background-image: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  @extend .shadow-dark;
}

.form-control:focus {
  @extend .shadow-dark;
  outline: 0;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #aeacca;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aeacca;
}

.form-control::placeholder {
  color: #aeacca;
}

.form-group {
  margin-bottom: 30px;
}

.input-group-addon {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

/* === Alert === */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 20px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: 0;
  right: 0;
  color: inherit;
}

/* === Pagination === */
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-radius: 50%;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 50%;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: $colordefault;
  border-color: transparent;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  font-weight: 400;
  height: 46px;
  line-height: 32px !important;
  margin-left: 11px;
  line-height: 1.42857143;
  color: #1a1a1a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 50%;
  width: 46px;
  text-align: center;
  @include transition(0.3s);
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #fff;
  background-color: $colordefault;
  border-color: transparent;
}

/*=================================================================*/
/*                      THEME ELEMENTS
/*=================================================================*/
/* === Timeline === */
.timeline {
  position: relative;
}

.timeline .timeline-container {
  padding-left: 50px;
  margin-bottom: 50px;
  position: relative;
  background-color: inherit;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.timeline.edu .timeline-container::after {
  content: "\e019";
  background: #fff;
  font-family: "simple-line-icons";
  font-size: 24px;
  color: black;
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline.exp .timeline-container::after {
  content: "\e04b";
  background: #fff;
  font-family: "simple-line-icons";
  font-size: 24px;
  color: $colorpink;
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline .content {
  position: relative;
}

.timeline .content .time {
  color: #8b88b1;
  font-size: 14px;
}

.timeline .content h3 {
  font-size: 20px;
  margin: 10px 0;
}

.timeline .content p {
  margin: 0;
}

.timeline span.line {
  position: absolute;
  width: 1px;
  background-color: $colorpink;
  top: 30px;
  bottom: 30px;
  left: 34px;
}

/* === Social Icons === */
.social-icons {
  li {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
    a {
      font-size: 21px;
    }
  }
  &.light {
    li {
      a:hover {
        color: #66fcf1;
      }
    }
  }
}

/* === Go to Top === */
#return-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 100%;
  display: none;
  z-index: 4;
  @include transition(0.3s);
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 13px;
  top: 8px;
  font-size: 16px;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  @include transition(0.1s);
}

#return-to-top:hover {
  background: $colorpink;
}

/* === Helper === */
.rounded {
  border-radius: 20px !important;
}

.bg-white {
  background: #fff;
}

.shadow-dark {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
  -moz-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.shadow-light {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
  -moz-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
  -moz-box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
}

.shadow-pink {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
  -moz-box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
}

.shadow-yellow {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
  -moz-box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
}

.padding-30 {
  padding: 20px;
}

.triangle-left {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #fff;
    position: absolute;
    left: -15px;
    top: 20%;
  }
}

.triangle-top {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-7.5px);
  }
}

.morphext > .animated {
  display: inline-block;
}

.text-light {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// ====== Magnific Popup ======
.mfp-zoom-in {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

/* Styles for dialog window */
.white-popup {
  background: white;
  border-radius: 25px;
  padding: 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}

/**
   * Fade-zoom animation for first dialog
   */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-close {
  font-size: 40px;
  opacity: 1;
  &:hover {
    opacity: 0.75;
  }
}

//animated border and

.animatedborder {
  // font-size: 24px;
  border: 10px solid black;
  // resize: both;
  // background: #ffd73e33;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%232d3561' /%3E%3Cstop offset='25%25' stop-color='%23c05c7e' /%3E%3Cstop offset='50%25' stop-color='%23f3826f' /%3E%3Cstop offset='100%25' stop-color='%23ffb961' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
    1;
}

*::before,
*::after {
  box-sizing: border-box;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.rainbow {
  position: relative;
  z-index: 0;

  border-radius: 10px;
  overflow: hidden;
  // padding: 2rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: #353353;
    border-radius: 5px;
  }
}

.rainbow5 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#09e2ff, #00fb21), linear-gradient(#0da6ff, #33d597),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: #353353;
    border-radius: 5px;
  }
}

.rainbow2 {
  position: relative;
  z-index: 0;

  border-radius: 10px;
  overflow: hidden;
  // padding: 2rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#09e2ff, #00fb21), linear-gradient(#0da6ff, #33d597),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: #353353;
    border-radius: 5px;
  }
}

.rainbow3 {
  position: relative;
  z-index: 0;

  border-radius: 10px;
  overflow: hidden;
  // padding: 2rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff), linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: #353353;
    border-radius: 5px;
  }
}

//news
.box2 {
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.box2::before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  background: repeating-linear-gradient(
    white 0%,
    white 7.5px,
    hotpink 7.5px,
    hotpink 15px,
    white 15px,
    white 22.5px,
    hotpink 22.5px,
    hotpink 30px
  );
  transform: translateX(-20%) translateY(-20%) rotate(-45deg);
  animation: animate 20s linear infinite;
}

.box2 .content {
  position: relative;
  background-color: white;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
  font-family: sans-serif;
  z-index: 2;
}

.box2,
.box2 .content {
  box-shadow: 0 0 2px deeppink, 0 0 5px rgba(0, 0, 0, 1),
    inset 0 0 5px rgba(0, 0, 0, 1);
  border-radius: 10px;
}

.box2 .content h2 {
  color: deeppink;
}

.box2 .content p {
  color: dimgray;
}

@keyframes animate {
  from {
    background-position: 0;
  }

  to {
    background-position: 0 450px;
  }
}

.teamSelector {
  cursor: pointer;
}

//button animation
$black: #000;
$white: #fff;
$shocking-pink: #ff1ead;

.ti-btn {
  color: $white;
  font: inherit;
  background-color: $black;
  border: solid 4px $white;
  padding: 20px 30px;
  outline: 0;
  overflow: hidden;
  display: inline-block;
  position: relative;
  user-select: none;
  box-shadow: 0 0 0 0 rgba($white, 0.5);
  transition: box-shadow 150ms ease-out;
  &:focus {
    box-shadow: 0 0 0 8px rgba($white, 0.5);
  }
}

.ti-btn2 {
  color: $white;
  font: inherit;
  background-color: $black;
  border: solid 4px $white;
  padding-left: 50px;
  padding-right: 50px;
  outline: 0;
  overflow: hidden;
  display: inline-block;
  // make the text bold
  font-weight: 20px;
  position: relative;
  user-select: none;
  box-shadow: 0 0 0 0 rgba($white, 0.5);
  transition: box-shadow 150ms ease-out;
  &:focus {
    box-shadow: 0 0 0 8px rgba($white, 0.5);
  }
}
.content4 {
  font-size: 25px;
  // font-family: "Times New Roman", Times, serif;
  font-weight: 50px;
}

//TODO make the button look cool for footer
.btn4 {
  cursor: pointer;
  background-color: white;
  // width: 330px;
  // height: 64px;
  // line-height: 64px;
  // position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.34);
  // span {
  //   color: #fff;
  //   display: block;
  //   padding-left: 35px;
  //   text-transform: uppercase;
  //   font: bold 18px/66px Arial;
  //   transform: scaleX(0.6);
  //   letter-spacing: 3px;
  //   transform-origin: center left;
  //   transition: color 0.3s ease;
  //   position: relative;
  //   z-index: 1;
  // }
  // em {
  //   position: absolute;
  //   height: 1px;
  //   background: #fff;
  //   width: 47%;
  //   right: 23px;
  //   top: 50%;
  //   transform: scaleX(0.25);
  //   transform-origin: center right;
  //   transition: all 0.3s ease;
  //   z-index: 1;
  // }
  &:before,
  &:after {
    content: "";
    background-color: #e0a800;
    // color: black;
    height: 50%;
    width: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &:before {
    top: 0;
    left: 0;
    right: auto;
  }
  &:after {
    bottom: 0;
    right: 0;
    left: auto;
  }
  &:hover {
    &:before {
      width: 100%;
      right: 0;
      left: auto;
      // color: black;
    }
    &:after {
      width: 100%;
      left: 0;
      right: auto;
      // color: black;
    }
    span {
      color: #000;
    }
  }
}

// .ti-btn:hover::after {
//   content: "Hold down the click to see some cool stuff";
// }

// .ti-btn:hover  {
//   display: none;
// }
.ti-btn:active {
  background-color: $shocking-pink;
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-radius: 50%;
  transform: translateX(-100%) translateY(-100%);
  mix-blend-mode: screen;
  animation: ripple 2000ms ease-out forwards, fade 5000ms ease-out forwards;
}

@keyframes ripple {
  0% {
    transform: translate(-100%, -100%);
  }
  80% {
    transform: translate(-100%, -100%) scale(50);
  }
  100% {
    transform: translate(-100%, -100%) scale(50);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*=================================================================*/


/*                      DARK MODE                              
/*=================================================================*/

body.dark {
    color: black;
    background-color: #353d43;
    .section-title {
        color: #66fcf1;
        &:before {
            background: url("/images/dots-bg-light.svg");
        }
    }
    .bg-dark {
        background: #c1c1d1 !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: black;
    }
    blockquote {
        background: #5f5ca6;
    }
    .triangle-top:before {
        border-bottom-color: #302f4e;
    }
    .timeline.exp .timeline-container::after,
    .timeline.edu .timeline-container::after {
        background: #fffff0;
    }
    .portfolio-filter li button {
        color: #fff;
    }
    a:hover {
        color: #66fcf1;
    }
    .portfolio-filter li button.current {
        color: #66fcf1;
    }
    .blog-item .details h4.title a {
        color: #fffff0;
        &:hover {
            color: $colorpink;
        }
    }
    .contact-info {
        background-image: url("/images/map-light.png");
    }
    .white-popup {
        background: #302f4e;
    }
    footer.footer {
        border-top: solid 1px rgba(255, 255, 255, 0.1);
    }
    .content-blog,
    .blog-page-section {
        background: #353353;
    }
    .bg-white {
        background-color: #fffff0 !important;
    }
    .tempcolor {
        background-color: white;
        border-color: red;
    }
    .blog-standard .meta li a,
    .blog-standard .title a,
    .comment-author .fn {
        color: #fff;
    }
    .blog-page-section .blog-pagination ul li {
        background-color: #302f4e !important;
    }
    .blog-page-section .blog-single {
        background-color: #302f4e !important;
    }
}

.heights {
    height: 100%;
}

// .height2 {
//   height: 300px;
//   /* background-color: #0ac593; */
// }
.height3 {
    height: 355px;
}
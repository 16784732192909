.borderstyle {
    border-radius: 40px;
    border: 1px solid;
    background-color: #fff;
}

.analytics-button-2 {
    margin-left: 37px
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 750px !important;
        margin: 1.75rem auto;
    }
}

@media screen and (max-width: 766px) {
    .portfolio-filter {
        margin-right: 10px;
        margin-left: 10px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    .analytics-button-2 {
        width: 100%;
        margin-right: 12px;
        margin-left: 12px;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1357px) {
    .analytics-button-2 {
        margin-left: 0px !important;
    }
}

button.sc-bdnxRM.fzCwXm.rec.rec-arrow.rec.rec-arrow-right {
    background-color: white;
}

button.sc-bdnxRM.fzCwXm.rec.rec-arrow.rec.rec-arrow-left {
    background-color: white;
}

button.sc-bdnxRM.fzCwXm.rec.rec-arrow.rec.rec-arrow-left:hover {
    color: black;
}

button.sc-bdnxRM.fzCwXm.rec.rec-arrow.rec.rec-arrow-right:hover {
    color: black;
}

.Select-menu-outer {}

.bringFront {
    z-index: 999 !important;
}

.bringBack {
    z-index: 2;
}

*/ .tempcolor {
    background-color: white;
    border: 1px;
    border-color: White;
}

#home>.container>.intro {
    animation: bounceInDown 1000ms both;
}

.bgradients {
    background: #353d43;
}

.hello {
    margin: 0;
    font-size: 4em;
    padding: 0;
    font-weight: bold;
    color: white;
    text-shadow: 0 0.1em 20px rgb(255 255 255), 0.05em -0.03em 0 rgb(0 0 0), 0.05em 0.005em 0 rgb(0 0 0), 0em 0.08em 0 rgb(0 0 0), 0.05em 0.08em 0 rgb(0 0 0), 0px -0.03em 0 rgb(0 0 0), -0.03em -0.03em 0 rgb(0 0 0), -0.03em 0.08em 0 rgb(0 0 0), -0.03em 0 0 rgb(0 0 0);
}

.black {
    background-color: aqua;
}

@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }
    75% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

#home>.container>.box>.parallax {
    animation: slide3dInUp 2300ms both;
    transform-style: preserve-3d;
}

@keyframes slide3dInUp {
    from {
        transform-origin: 50% 100%;
        transform: perspective(200px) translate3d(0, 200%, -100px) rotateX(60deg);
        opacity: 0;
    }
    50% {
        transform-origin: 50% 100%;
        transform: perspective(200px) rotateX(45deg);
        opacity: 1;
    }
    to {
        transform-origin: 50% 100%;
        transform: perspective(200px);
    }
}

.photoHero {
    height: 200px;
    /* width: 150px; */
    /* border-radius: 40px; */
    /* border: 1px solid; */
    /* border-color: black; */
}

.color {
    color: aqua;
    background-color: white;
}

.middle2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heights1 {
    height: 100%;
    width: 100%;
}

.height25 {
    height: 100px;
}
.focus {
  transition: transform 0.3s;
}

.focus:hover {
  transform: scale(1.05);
}

.card-title {
  font-size: 2em;
  font-weight: 700;
  color: black;
}

.image-style {
  width: 3em;
}

.font-details {
  font-size: 3em;
  font-weight: 500;
  color: #231d5af8;
}

.font-details-b {
  font-size: 3em;
  font-weight: 500;
  color: #ffffff;
}

.card-img-wrapper:hover {
  transform: scale(1);
}
.card-img-wrapper {
  transition: 1.5s ease;
}

.card-img-wrapper2:hover {
  transform: scale(1.15);
}
.card-img-wrapper2 {
  transition: 1s ease;
}

/* body {
  background: #3da3d5;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(53, 53, 53);
} */

.servicetitle {
    float: left;
}

.imageservicetitle {
    float: left;
    margin-left: 10px;
}

.imagecarousel {
    height: 418px;
    width: 736px;
}

.title {
    font-size: 5em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
}


/* @media screen and (min-width: 900px) and (max-width: 1357px) {
    .title {
        font-size: 5rem;
        text-align: center;
        font-family: "Bebas Neue", sans-serif;
    }
} */


/* 
.portfolio-wrapper [class*="col-"] {
  margin-top: 0px;
} */

.middle2 span {
    margin: 0px;
}


/* h3 {
  padding-top: 0.25em;
} */

.date {
    color: #c9fbff;
}

#description {
    margin: 1.5em 0 2em 0;
}


/* 
.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
} */

.hJJzxj:focus:enabled,
.hJJzxj:hover:enabled,
.jYxeHE {
    background-color: #66fcf1;
}

.workButton {
    background-color: #06d6a0;
}

.workButton:hover {
    background-color: #0ac593;
}

.schoolButton {
    background-color: #f9c74f;
}

.schoolButton:hover {
    background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
        display: block !important;
        float: none !important;
        color: #c9fbff;
        margin-top: 1.5em;
    }
}

.teamSelector {
    cursor: pointer;
}


/*  .dark {
  background-color: black;
  border-color: red;
  border-width: 2px;
  justify-self: center;
} */


/* #works > .container {
  animation: slideInLeft 1900ms 100ms both;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
} */

.white {
    background-color: white;
    border-width: 2px;
    border-color: purple;
}


/* hover effect 
 */


/* .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
} */

.card-img-wrapper:hover img {
    transform: scale(1.15);
}

.card-img-wrapper img {
    transition: 1.5s ease;
}

.my-button {
    display: flex;
    align-items: center;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
    filter: invert(100%);
}

.right {
    order: 1;
}

.middle {
    position: relative;
    left: 50;
    background-color: white;
}

.photo {
    height: 60px;
    width: 220px;
    /* border: 1px solid; */
    /* border-color: black; */
}

.middle2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.height4 {
    height: 2050px;
}

.btn-static {
    /* background-color: yellow; */
    /* border: 1px solid lightgrey; */
    border-radius: 40px;
    cursor: default;
}

.dark {
    background-color: grey;
    border-color: red;
    border-width: 2px;
}

div.sc-jSFjdj.jvXOvp.rec.rec-carousel-wrapper {
    padding: 40px;
}

.heights {
    height: 100%;
}

.heights11 {
    height: 100%;
    display: flex;
}

.height2 {
    height: 370px;
    /* flex: 1; */
    /* background-color: #0ac593; */
}

.height22 {
    /* height: 300px; */
    flex: 1;
    /* background-color: #0ac593; */
}

.height55 {
    height: 180px;
    width: 100%;
    /* background-color: #0ac593; */
}

.height21 {
    min-height: 350px;
}

@media only screen and (max-width: 850px) {
    .height21 {
        min-height: 150px;
    }
}

@media only screen and (min-width: 850px) and (max-width: 900px) {
    .height21 {
        min-height: 390px;
    }
}

.height25 {
    height: px;
    /* background-color: #0ac593; */
}

.height3 {
    height: 355px;
}

.title {
    font-size: 15em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
}

h3 {
    padding-top: 0.25em;
}


/* div.service-box.rounded.data-background.padding-30.text-center.shadow-blue {
    height: 100%;
} */

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    background: black;
}

button.sc-bczRLJ.hJJzxj.rec.rec-arrow.rec.rec-arrow-right {
    background-color: #353d43;
    color: black;
}

button.sc-bczRLJ.hJJzxj.rec.rec-arrow.rec.rec-arrow-right:hover,
button.sc-bczRLJ.hJJzxj.rec.rec-arrow.rec.rec-arrow-right:hover:enabled {
    background-color: #66fcf1;
}

button.sc-bczRLJ.hJJzxj.rec.rec-arrow.rec.rec-arrow-left:hover,
button.sc-bczRLJ.hJJzxj.rec.rec-arrow.rec.rec-arrow-left:hover:enabled {
    background-color: #66fcf1;
}

button.sc-bczRLJ.hJJzxj.rec.rec-arrow.rec.rec-arrow-left {
    color: black;
    background-color: #353d43;
}

.date {
    color: rgb(201, 251, 255);
}

#description {
    margin: 1.5em 0 2em 0;
}

h5.middle2 {
    margin: 0px;
    padding: 0px;
}

.button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
}

.workButton {
    background-color: #06d6a0;
}

.workButton:hover {
    background-color: #0ac593;
}

.schoolButton {
    background-color: #f9c74f;
}

.schoolButton:hover {
    background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
        display: block !important;
        float: none !important;
        color: #c9fbff;
        margin-top: 1.5em;
    }
}

div.container.dark.padding-40 {
    padding-left: 40px;
    padding-right: 40px;
}


/* timeline
 */

div.vertical-timeline-element-content.bounce-in {
    /* background: grey; */
    background-color: black;
    padding: 0;
}

.btn-static1 {
    background-color: white;
    border: 1px solid lightgrey;
    cursor: default;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 4 !important;
    /* background: black; */
    border-radius: 20px !important;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
    background-color: #fffff0 !important;
    -moz-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
    box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}


/* .vertical-timeline-element-content p {
  margin-bottom: 0px;
  line-height: 1.6;
} */


/* p.rounded.shadow-dark.overflow-hidde {
  margin-bottom: 0px;
} */


/* div .g p {
  margin: 0px;
  padding: 0px;
} */

@keyframes modalFade {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

div.sc-iBkjds.hHSNMm.rec.rec-pagination {
    background: #fffff0;
    border-radius: 40px;
}

button.sc-gKXOVf.jYxeHE.rec.rec-dot.rec.rec-dot_active {
    background-color: #66fcf1;
    box-shadow: #66fcf1;
}

.jYxeHE {
    box-shadow: #66fcf1;
}

.modal {
    animation-name: modalFade;
    animation-duration: 0.3s;
}

.font {
    font-family: "Nunito";
    font-size: 20px;
    /* font-weight: bold; */
}

.gradient-border {
    --borderWidth: 3px;
    background: #1d1f20;
    position: relative;
    border-radius: var(--borderWidth);
}

.gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient( 60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.clip {
  /* background-color: White; */
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 20%;
  /* display: block;
  margin: 0 auto;
  border-color: red; */
}
